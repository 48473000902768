import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WelcomePage from './WelcomePage';
import Edit from './Edit';
import LoadPage from './LoadPage';
import 'katex/dist/katex.min.css';

function Main() {
    const [files, setFiles] = useState([
        {
            "name": "Welcome !",
            "type": "file",
            "content": "!!Bienvenue sur Oberta!!\n\nBienvenue sur **Oberta**, un éditeur simple avec un langage personnalisé pour structurer ton contenu.\n\nVoici quelques exemples de ce que tu peux faire :\n\n__Titres :__\n#Grand titre#\n##Sous-titre##\n\n__Texte stylisé :__\n- !!Gras!!\n- //Italique//\n- __Souligné__\n- ~~Barré~~\n\n__Blocs de code :__\n``code inline``\n\n```!!Bloc de code!! plus grand```\n\n__Liens :__\n[Clique ici https://www.example.com]\n\n---\n\n!!!information!!!\nIci tu peux inclure des sections spéciales comme des avertissements, des informations, des conseils, etc.\n!!!\n\nBon courage pour l'utilisation d'Oberta !"
        }
    ]
    );

    return (
        <div className='overflow-hidden'>
            <Router>
                <Routes>
                    <Route path="/" element={<WelcomePage />} />
                    <Route path="/edit" element={<Edit files={files} setFiles={setFiles} />} />
                    <Route path="/load" element={<LoadPage setFiles={setFiles} />} />
                </Routes>
            </Router>
        </div>
    );
}

export default Main;