import Main from "./pages/Main";

function App() {
  return (
    <div className="h-screen w-screen overflow-hidden">
      <div className="h-full w-full">
        <Main></Main>
      </div>
    </div>
  );
}

export default App;