import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseCustomSyntax } from '../core/syntaxParser';
import fileIcon from '../images/noun-7225855-FFFFFF.svg';
import folderIcon from '../images/noun-7235206-FFFFFF.svg';
import fileAddIcon from '../images/noun-7165070-FFFFFF.svg';
import folderAddIcon from '../images/noun-create-folder-6134871-FFFFFF.svg';
import downloadIcon from '../images/noun-7170572-FFFFFF.svg';
import removeIcon from '../images/noun-4498862-FFFFFF.svg';
import backIcon from '../images/noun-7172168-FFFFFF.svg';

const generateId = () => `_${Math.random().toString(36).substr(2, 9)}`;

function Edit({ files, setFiles }) {
    const [currentFile, setCurrentFile] = useState(null);
    const [text, setText] = useState("");
    const [modalType, setModalType] = useState(null);
    const [modalData, setModalData] = useState({});
    const [fileNameInput, setFileNameInput] = useState('');
    const [draggedItem, setDraggedItem] = useState(null);
    const textareaRef = useRef(null);
    const previewRef = useRef(null);
    const inputRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (modalType) {
            setFileNameInput('');
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [modalType]);

    const handleInputChange = (e) => {
        setText(e.target.value);
        if (currentFile) {
            const updatedFiles = updateFileContent(files, currentFile.id, e.target.value);
            setFiles(updatedFiles);
        }
    };

    const updateFileContent = (fileList, fileId, newContent) => {
        return fileList.map(file => {
            if (file.id === fileId) {
                return { ...file, content: newContent };
            } else if (file.type === 'folder') {
                return { ...file, files: updateFileContent(file.files, fileId, newContent) };
            }
            return file;
        });
    };

    const openModal = (type, parentFolder = null) => {
        setModalType(type);
        setModalData({ parentFolder });
    };

    const handleCreate = () => {
        if (!fileNameInput.trim()) {
            alert("Le nom ne peut pas être vide.");
            return;
        }

        if (modalType === 'file') {
            const newFile = { id: generateId(), name: fileNameInput.trim(), type: 'file', content: '' };
            if (modalData.parentFolder) {
                modalData.parentFolder.files.push(newFile);
                setFiles([...files]);
            } else {
                setFiles([...files, newFile]);
            }
        } else if (modalType === 'folder') {
            const newFolder = { id: generateId(), name: fileNameInput.trim(), type: 'folder', files: [] };
            if (modalData.parentFolder) {
                modalData.parentFolder.files.push(newFolder);
                setFiles([...files]);
            } else {
                setFiles([...files, newFolder]);
            }
        }
        setModalType(null);
        setFileNameInput('');
    };

    const deleteFileOrFolder = (file) => {
        setModalData({ file });
        setModalType('delete');
    };

    const handleDelete = () => {
        const file = modalData.file;
        if (file && file.type === 'file') {
            const updatedFiles = removeFileOrFolder(files, file.id);
            setFiles(updatedFiles);
            setText('');
            setCurrentFile(null);
        } else if (file && file.type === 'folder') {
            handleFolderDelete(file);
        }
        setModalType(null);
    };

    const handleFolderDelete = (folder) => {
        const updatedFiles = removeFileOrFolder(files, folder.id);
        setFiles(updatedFiles);
    };

    const removeFileOrFolder = (fileList, fileId) => {
        return fileList.filter(file => file.id !== fileId)
            .map(file => {
                if (file.type === 'folder') {
                    return { ...file, files: removeFileOrFolder(file.files, fileId) };
                }
                return file;
            });
    };

    const changeFile = (file) => {
        if (currentFile) {
            const updatedFiles = updateFileContent(files, currentFile.id, text);
            setFiles(updatedFiles);
        }
        setCurrentFile(file);
        setText(file.content || "");
    };

    const moveItemInJSON = (fileList, itemId, targetFolderId = null) => {
        let draggedItem = null;
        let updatedFiles = [];

        const removeItem = (fileList) => {
            return fileList.reduce((acc, file) => {
                if (file.id === itemId) {
                    draggedItem = file;
                    return acc;
                }

                if (file.type === 'folder') {
                    const updatedFolder = removeItem(file.files);
                    return [...acc, { ...file, files: updatedFolder }];
                }

                return [...acc, file];
            }, []);
        };

        updatedFiles = removeItem(fileList);

        if (!draggedItem) return fileList;

        if (!targetFolderId) {
            updatedFiles.push(draggedItem);
        } else {
            const insertIntoFolder = (folder) => {
                if (folder.id === targetFolderId) {
                    if (isChildOf(folder, draggedItem)) {
                        alert("Vous ne pouvez pas déplacer un dossier dans lui-même ou un de ses sous-dossiers.");
                        return folder;
                    }
                    return { ...folder, files: [...folder.files, draggedItem] };
                }

                if (folder.type === 'folder') {
                    return {
                        ...folder,
                        files: folder.files.map(subFile => insertIntoFolder(subFile)),
                    };
                }

                return folder;
            };

            updatedFiles = updatedFiles.map(file => insertIntoFolder(file));
        }

        return updatedFiles;
    };

    const isChildOf = (parent, potentialChild) => {
        if (parent.id === potentialChild.id) {
            return true;
        }
        if (parent.files && parent.files.length > 0) {
            return parent.files.some(subFile => isChildOf(subFile, potentialChild));
        }
        return false;
    };

    const handleDrop = (e, targetFolder = null) => {
        e.preventDefault();
        if (draggedItem) {
            if (targetFolder && draggedItem.id === targetFolder.id) {
                alert("Vous ne pouvez pas déplacer un fichier ou un dossier dans lui-même.");
                return;
            }
            const updatedFiles = moveItemInJSON(files, draggedItem.id, targetFolder ? targetFolder.id : null);
            setFiles(updatedFiles);
            setDraggedItem(null);
        }
    };

    const handleDragStart = (e, item) => {
        setDraggedItem(item);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const renderRootDropZone = () => {
        return (
            <div
                className="border-dashed border-2 border-gray-300 p-4 mb-4 text-center text-white"
                onDrop={(e) => handleDrop(e, null)}
                onDragOver={handleDragOver}
            >
                Déposez ici pour déplacer à la racine
            </div>
        );
    };

    const renderFiles = (fileList, depth = 0) => {
        const sortedFiles = fileList.sort((a, b) => {
            if (a.type === 'folder' && b.type === 'file') return -1;
            if (a.type === 'file' && b.type === 'folder') return 1;
            return a.name.localeCompare(b.name);
        });

        return (
            <div className='overflow-x-auto'>
                {depth === 0 && renderRootDropZone()}
                <ul className="list-none">
                    {sortedFiles.map((file, index) => (
                        <li key={index} className={`mb-2 ml-${depth * 4}`}>
                            {file.type === 'folder' ? (
                                <div
                                    className="flex items-center justify-between"
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, file)}
                                    onDrop={(e) => handleDrop(e, file)}
                                    onDragOver={handleDragOver}
                                >
                                    <div className="flex items-center">
                                        <img src={folderIcon} alt="folder" className="h-5 w-5 mr-2" />
                                        {file.name}
                                    </div>
                                    <div className="flex ml-auto gap-2">
                                        <button onClick={() => openModal('file', file)} className="bg-gray-800 p-1 rounded">
                                            <img src={fileAddIcon} alt="Créer un fichier" className="h-4 w-4" />
                                        </button>
                                        <button onClick={() => openModal('folder', file)} className="bg-gray-800 p-1 rounded">
                                            <img src={folderAddIcon} alt="Créer un dossier" className="h-4 w-4" />
                                        </button>
                                        <button onClick={() => deleteFileOrFolder(file)} className="bg-red-600 p-1 rounded">
                                            <img src={removeIcon} alt="Supprimer" className="h-4 w-4" />
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="flex items-center justify-between"
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, file)}
                                >
                                    <div className="flex items-center">
                                        <img src={fileIcon} alt="file" className="h-5 w-5 mr-2" />
                                        <button onClick={() => changeFile(file)}>{file.name}</button>
                                    </div>
                                    <button onClick={() => deleteFileOrFolder(file)} className="bg-red-600 p-1 rounded">
                                        <img src={removeIcon} alt="Supprimer" className="h-4 w-4" />
                                    </button>
                                </div>
                            )}
                            {file.type === 'folder' && file.files.length > 0 && (
                                <div className="ml-4">
                                    {renderFiles(file.files, depth + 1)}
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const downloadJSON = () => {
        const json = JSON.stringify(files, null, 2);
        const blob = new Blob([json], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'files.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="flex h-screen bg-primary p-4 gap-4 overflow-hidden">
            <div className="w-1/6 bg-complementary2 text-white p-4 rounded-lg h-full overflow-auto">
                <h2 className="text-xl font-bold mb-4">Explorateur de fichiers</h2>
                <div className="flex gap-2 mb-4">
                    <button onClick={() => openModal('file')} className="bg-gray-800 p-2 rounded">
                        <img src={fileAddIcon} alt="Créer un fichier" className="h-6 w-6" />
                    </button>
                    <button onClick={() => openModal('folder')} className="bg-gray-800 p-2 rounded">
                        <img src={folderAddIcon} alt="Créer un dossier" className="h-6 w-6" />
                    </button>
                </div>
                {renderFiles(files)}
            </div>

            <textarea
                ref={textareaRef}
                className="w-5/12 h-full border-2 shadow-inner p-2 rounded-lg resize-none overflow-auto"
                value={text}
                onChange={handleInputChange}
                placeholder="Écris ton texte ici..."
            />

            <div
                ref={previewRef}
                className="border-2 p-4 h-full w-5/12 rounded-lg overflow-auto bg-white"
                dangerouslySetInnerHTML={{ __html: parseCustomSyntax(text) }}
            />

            <button onClick={downloadJSON} className="fixed bottom-4 right-4 bg-blue-600 text-white p-3 rounded-full shadow-lg">
                <img src={downloadIcon} alt="Télécharger JSON" className="h-6 w-6" />
            </button>

            <button onClick={() => navigate('/')} className="fixed bottom-4 left-4 bg-green-600 text-white p-3 rounded-full shadow-lg">
                <img src={backIcon} alt="Retourner à l'accueil" className="h-6 w-6" />
            </button>

            {modalType && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <h2 className="text-lg font-bold mb-4">{modalType === 'file' ? 'Créer un fichier' : 'Créer un dossier'}</h2>
                        <input
                            ref={inputRef}
                            type="text"
                            placeholder="Nom"
                            value={fileNameInput}
                            onChange={(e) => setFileNameInput(e.target.value)}
                            className="border p-2 rounded w-full mb-4"
                        />
                        <div className="flex justify-end">
                            <button onClick={() => setModalType(null)} className="bg-red-500 text-white px-4 py-2 rounded mr-2">Annuler</button>
                            <button onClick={handleCreate} className="bg-blue-500 text-white px-4 py-2 rounded">Créer</button>
                        </div>
                    </div>
                </div>
            )}

            {modalType === 'delete' && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <h2 className="text-lg font-bold mb-4">Confirmer la suppression</h2>
                        <p>Voulez-vous vraiment supprimer "{modalData.file?.name}" ?</p>
                        <div className="flex justify-end mt-4">
                            <button onClick={() => setModalType(null)} className="bg-gray-500 text-white px-4 py-2 rounded mr-2">Annuler</button>
                            <button onClick={handleDelete} className="bg-red-500 text-white px-4 py-2 rounded">Supprimer</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Edit;
