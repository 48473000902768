import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NewIcon } from '../images/noun-new-4670966-FFFFFF.svg';
import { ReactComponent as LoadIcon } from '../images/noun-load-4700284-FFFFFF.svg';

function WelcomePage() {
    const navigate = useNavigate();

    const handleNewClick = () => {
        navigate('/edit');
    };

    const handleLoadClick = () => {
        navigate('/load');
    };

    return (
        <div className="flex flex-col justify-center items-center h-screen bg-primary">
            <h1 className="text-white text-4xl font-bold mb-8">Oberta</h1>

            <div className="grid grid-cols-2 gap-10">
                <button 
                    onClick={handleNewClick}
                    className="flex flex-col items-center justify-center w-40 h-40 bg-complementary2 text-white rounded-lg shadow-md transform transition duration-300 hover:scale-110 hover:bg-complementary1"
                >
                    <NewIcon className="w-16 h-16" />
                    <span className="mt-2 text-lg">Nouveau</span>
                </button>

                <button 
                    onClick={handleLoadClick}
                    className="flex flex-col items-center justify-center w-40 h-40 bg-secondary text-white rounded-lg shadow-md transform transition duration-300 hover:scale-110 hover:bg-complementary1"
                >
                    <LoadIcon className="w-16 h-16" />
                    <span className="mt-2 text-lg">Charger</span>
                </button>
            </div>
        </div>
    );
}

export default WelcomePage;
