import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LoadPage({ setFiles }) {
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const json = JSON.parse(event.target.result);
                    setFiles(json);
                    navigate('/edit');
                } catch (error) {
                    setErrorMessage("Le fichier n'est pas un JSON valide.");
                }
            };
            reader.readAsText(file);
        }
    };

    return (
        <div className="flex flex-col justify-center items-center h-screen bg-primary">
            <h1 className="text-white text-4xl font-bold mb-8">Charger un fichier JSON</h1>
            {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
            <input
                type="file"
                accept=".json"
                onChange={handleFileChange}
                className="text-white"
            />
        </div>
    );
}

export default LoadPage;
