import katex from 'katex';

function parseBold(text) {
    return text.replace(/!!([\s\S]*?)!!/g, '<strong>$1</strong>');
}

function parseItalic(text) {
    return text.replace(/\/\/(?!https?:\/\/)([\s\S]*?)\/\//g, '<em>$1</em>');
}

function parseUnderline(text) {
    return text.replace(/__([\s\S]*?)__/g, '<u>$1</u>');
}

function parseStrikethrough(text) {
    return text.replace(/~~([\s\S]*?)~~/g, '<del>$1</del>');
}

function parseHorizontalRule(text) {
    return text.replace(/^\s*(---|___)\s*$/gm, '<hr class="my-4 border-gray-700" />');
}

function parseLinks(text) {
    const urlPattern = /\[(.*?)(https?:\/\/[^\s]+)\]/g;
    return text.replace(urlPattern, (match, linkText, url) => `<a href="${url}" class="text-lg text-blue-600 underline">${linkText}</a>`);
}

function parseTitles(text) {
    return text.replace(/(#{1,6})([\s\S]*?)\1/g, (match, p1, p2) => {
        const tagLevel = p1.length;

        if (tagLevel > 6) {
            return match;
        }

        const tailwindSize = [
            'text-6xl',
            'text-5xl',
            'text-4xl',
            'text-3xl',
            'text-2xl',
            'text-xl'
        ][tagLevel - 1];

        return `<h${tagLevel} class="bold ${tailwindSize}">${p2.trim()}</h${tagLevel}>`;
    });
}

function parseSections(text) {
    return text.replace(/!!!(avertissement|remarque|information|correction|succes|conseil|erreur|astuce|attention|info|exemple|alerte|reponse)!!!\s*([\s\S]*?)\s*!!!/gs, (match, sectionType, content) => {
        let title = '';
        let backgroundColor = '';
        let borderColor = '';
        let contentBackgroundColor = '';

        switch (sectionType.toLowerCase()) {
            case 'avertissement':
                title = 'Avertissement';
                backgroundColor = 'bg-red-600';
                borderColor = 'border-red-600';
                contentBackgroundColor = 'bg-red-600 bg-opacity-60';
                break;
            case 'remarque':
                title = 'Remarque';
                backgroundColor = 'bg-yellow-500';
                borderColor = 'border-yellow-500';
                contentBackgroundColor = 'bg-yellow-500 bg-opacity-60';
                break;
            case 'information':
                title = 'Information';
                backgroundColor = 'bg-blue-500';
                borderColor = 'border-blue-500';
                contentBackgroundColor = 'bg-blue-500 bg-opacity-60';
                break;
            case 'correction':
                title = 'Correction';
                backgroundColor = 'bg-green-500';
                borderColor = 'border-green-500';
                contentBackgroundColor = 'bg-green-500 bg-opacity-60';
                break;
            case 'succes':
                title = 'Succès';
                backgroundColor = 'bg-green-400';
                borderColor = 'border-green-400';
                contentBackgroundColor = 'bg-green-400 bg-opacity-60';
                break;
            case 'conseil':
                title = 'Conseil';
                backgroundColor = 'bg-indigo-500';
                borderColor = 'border-indigo-500';
                contentBackgroundColor = 'bg-indigo-500 bg-opacity-60';
                break;
            case 'erreur':
                title = 'Erreur';
                backgroundColor = 'bg-red-800';
                borderColor = 'border-red-800';
                contentBackgroundColor = 'bg-red-800 bg-opacity-60';
                break;
            case 'astuce':
                title = 'Astuce';
                backgroundColor = 'bg-purple-400';
                borderColor = 'border-purple-400';
                contentBackgroundColor = 'bg-purple-400 bg-opacity-60';
                break;
            case 'attention':
                title = 'Attention';
                backgroundColor = 'bg-orange-500';
                borderColor = 'border-orange-500';
                contentBackgroundColor = 'bg-orange-500 bg-opacity-60';
                break;
            case 'info':
                title = 'Info';
                backgroundColor = 'bg-sky-400';
                borderColor = 'border-sky-400';
                contentBackgroundColor = 'bg-sky-400 bg-opacity-60';
                break;
            case 'exemple':
                title = 'Exemple';
                backgroundColor = 'bg-gray-400';
                borderColor = 'border-gray-400';
                contentBackgroundColor = 'bg-gray-400 bg-opacity-60';
                break;
            case 'alerte':
                title = 'Alerte';
                backgroundColor = 'bg-red-500';
                borderColor = 'border-red-500';
                contentBackgroundColor = 'bg-red-500 bg-opacity-60';
                break;
            case 'reponse':
                title = 'Réponse';
                backgroundColor = 'bg-redLilliac';
                borderColor = 'border-redLilliac';
                contentBackgroundColor = 'bg-redLilliac bg-opacity-60';
                break;
            default:
                return match;
        }

        const cleanedContent = content.replace(/\n/g, '').trim();

        return `
            <div class="no-br flex flex-col rounded-lg border-2 ${borderColor}">
                <div class="text-white font-bold ${backgroundColor} p-2 flex-shrink-0 flex items-center justify-center">${title}</div>
                <div class="text-white ${contentBackgroundColor} flex-grow p-4 no-br">
                    ${cleanedContent}
                </div>
            </div>`;
    });
}

function parseLatex(text) {
    return text.replace(/\$\$([\s\S]*?)\$\$/g, (match, latexContent) => {
        try {
            return katex.renderToString(latexContent, {
                throwOnError: false,
                displayMode: false
            });
        } catch (e) {
            return match;
        }
    });
}

export function parseCustomSyntax(text) {
    const codeBlocks = [];
    const inlineCodeBlocks = [];

    let parsedText = text.replace(/```([\s\S]*?)```/g, (match, codeContent, index) => {
        const placeholder = `<!--CODEBLOCK-${codeBlocks.length}-->`;
        codeBlocks.push(`<pre class="bg-primary text-white p-2 rounded-lg overflow-x-auto"><code>${codeContent}</code></pre>`);
        return placeholder;
    });

    parsedText = parsedText.replace(/``([\s\S]*?)``/g, (match, codeContent, index) => {
        const placeholder = `<!--INLINECODE-${inlineCodeBlocks.length}-->`;
        inlineCodeBlocks.push(`<code class="bg-primary text-white p-1 rounded">${codeContent}</code>`);
        return placeholder;
    });

    parsedText = parseLatex(parsedText);
    parsedText = parseHorizontalRule(parsedText);

    parsedText = parsedText.replace(/(?:\n)(?!(?:<div.*?>))/g, '<br />');

    parsedText = parseSections(parsedText);
    parsedText = parseBold(parsedText);
    parsedText = parseLinks(parsedText);
    parsedText = parseItalic(parsedText);
    parsedText = parseUnderline(parsedText);
    parsedText = parseStrikethrough(parsedText);
    parsedText = parseTitles(parsedText);

    parsedText = parsedText.replace(/<!--CODEBLOCK-(\d+)-->/g, (match, index) => codeBlocks[index]);
    parsedText = parsedText.replace(/<!--INLINECODE-(\d+)-->/g, (match, index) => inlineCodeBlocks[index]);

    return parsedText;
}
